import api from '../../api/api';
import * as ACTIONS from '../actionTypes';
import {
  UPDATE_CHANNEL_KEY,
  UPDATE_API_KEY,
  UPDATE_USER_INFO,
  CLEAR_USER_INFO,
  UPDATE_GEO_DATA,
  UPDATE_USER_FEATURES,
} from '../mutationTypes';
import {CHANNEL_KEY, CUSTOM_FORM_ALIASES, URL_QUERIES} from '@/const';
import {GET_CUSTOM_FORM_DATA} from '../actionTypes';
import {GET_BASIC_PASS_SELECTED} from '@/store/getterTypes';
import Vue from 'vue';

const alias = CUSTOM_FORM_ALIASES.PASSES;
export default {
  [ACTIONS.GET_CHANNEL_KEY]: async ({commit}) => {
    const key = CHANNEL_KEY && {key: CHANNEL_KEY} || await api.authApi.get_channel_key();
    commit(UPDATE_CHANNEL_KEY, key);
  },
  [ACTIONS.GET_API_KEY]: async ({commit}) => {
    commit(UPDATE_API_KEY, await api.authApi.get_api_key());
  },
  [ACTIONS.GET_PROFILE]: async ({commit, dispatch}) => {
    const res = await api.authApi.profile();
    commit(UPDATE_USER_INFO, res);
    commit(UPDATE_GEO_DATA, res?.data);
    await dispatch(GET_CUSTOM_FORM_DATA, alias);
  },
  [ACTIONS.GET_USER_ACCESS]: async ({commit}) => {
    const res = await api.authApi.getUserAccess();
    commit(UPDATE_USER_FEATURES, res.data);
  },
  [ACTIONS.DO_LOGIN]: async ({commit, dispatch}, params) => {
    const res = await api.authApi.login(params);
    commit(UPDATE_USER_INFO, res);
    commit(UPDATE_GEO_DATA, res?.data);
    await dispatch(GET_CUSTOM_FORM_DATA, alias);
  },
  [ACTIONS.DO_REGISTRATION]: async ({commit, dispatch}, params) => {
    const res = await api.authApi.registration(params);
    commit(UPDATE_USER_INFO, res);
    commit(UPDATE_GEO_DATA, res?.data);
    await dispatch(GET_CUSTOM_FORM_DATA, alias);
  },
  [ACTIONS.DO_LOGOUT]: async ({commit, getters}, params) => {
    const logged = getters.isLogged;
    const basicPassSelected = getters[GET_BASIC_PASS_SELECTED];
    if (logged && basicPassSelected) {
      Vue.$storage.remove(URL_QUERIES.V3_OUTER_TRACKING);
      commit(CLEAR_USER_INFO, await api.authApi.logout(params));
    } else {
      return Promise.reject(new Error('user can\'t be logged out'));
    }
  },
  [ACTIONS.DO_VERIFY]: async (state, params) => {
    return await api.authApi.verify(params);
  },
  [ACTIONS.DO_PASSWORD_RESET_REQUEST]: async (state, params) => {
    return await api.authApi.passwordResetRequest(params);
  },
  [ACTIONS.DO_PASSWORD_RESET]: async (state, params) => {
    return await api.authApi.passwordReset(params);
  },
  [ACTIONS.DO_UPDATE_PROFILE]: async ({commit}, params) => {
    const profile = await api.authApi.updateProfile(params);
    commit(UPDATE_USER_INFO, profile);
    commit(UPDATE_GEO_DATA, profile?.data);
    return profile;
  },
  [ACTIONS.SOCIAL_AUTH]: async ({
    commit,
    dispatch,
  }, params) => {
    const res = await api.authApi.socialAuth(params);
    commit(UPDATE_USER_INFO, res);
    commit(UPDATE_GEO_DATA, res?.data);
    await dispatch(GET_CUSTOM_FORM_DATA, alias);
  },
};
