<script>
import {mapActions} from 'vuex';
import {GET_API_KEY} from '@/store/actionTypes';
import ButtonComponent from '@/components/base-components/buttons/ButtonComponent.vue';

export default {
  name: 'ErrorReloadScreen',
  components: {ButtonComponent},
  ONE_MINUTE: 60000,
  ONE_SECOND: 1000,
  data() {
    return {
      seconds: 0,
      error: false,
      timeoutId: null,
      processing: false,
      urls: [],
    };
  },
  watch: {
    async error() {
      clearTimeout(this.timeoutId);
      this.seconds = this.error ? this.$options.ONE_MINUTE : 0;
      while (this.seconds) {
        const promise = new Promise((resolve) => {
          this.timeoutId = setTimeout(resolve, this.$options.ONE_SECOND);
        });
        await promise.then(() => {
          this.seconds -= this.$options.ONE_SECOND;
        });
      }
    },
    urls() {
      this.error = this.urls.length;
    },
  },
  methods: {
    ...mapActions({
      getApiKey: GET_API_KEY,
    }),
    onError({detail}) {
      if (detail?.error) {
        !this.urls.includes(detail.url) && this.urls.push(detail.url);
      } else if (this.urls.includes(detail.url)) {
        this.urls = this.urls.filter((url) => detail.url !== url);
      }
    },
    requestKey() {
      this.processing = true;
      this.getApiKey().then(() => {
        location.reload();
      }).finally(() => {
        this.processing = false;
      });
    },
  },
  created() {
    window.document.removeEventListener('api_key_error', this.onError);
    window.document.addEventListener('api_key_error', this.onError);
  },
};
</script>

<template>
  <div>
    <div v-if="error" class="reload-wrapper">
      <div class="reload-wrapper__icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <!-- eslint-disable-next-line max-len -->
          <path fill="currentColor" d="M250.594 20.906c-45.425.318-89.65 20.975-112.78 61.282c-22.594 39.374-34.23 82.722-31.314 115.406c1.458 16.34 6.393 29.793 14.72 39.5c8.325 9.706 20.104 16.173 37.53 18.03l11 1.19l-3 10.655c-2.337 8.272-3.75 16.256-3.75 24.905c0 27.038 4.292 79.342 18.5 123.563c7.104 22.11 16.715 42.157 28.78 56.093c12.068 13.938 25.855 21.845 43.814 21.845c17.96 0 31.777-7.907 43.844-21.844c12.066-13.935 21.677-33.982 28.78-56.092c14.21-44.22 18.5-96.525 18.5-123.563c0-8.65-1.41-16.635-3.75-24.906l-2.968-10.533l10.875-1.28c17.146-2.04 29.05-8.367 37.47-17.72c8.417-9.352 13.49-22.17 15-38c3.02-31.66-8.958-74.675-34.814-117.03c-25.5-41.774-70.927-61.8-116.374-61.5h-.062zM173.406 145.47c24.447 0 44.063 19.58 44.063 44.03a43.922 43.922 0 0 1-44.064 44.063c-24.446 0-44.03-19.617-44.03-44.063s19.584-44.03 44.03-44.03zm161.438 0c24.447 0 44.062 19.58 44.062 44.03a43.921 43.921 0 0 1-44.062 44.063c-24.447 0-44.03-19.617-44.03-44.063a43.864 43.864 0 0 1 44.03-44.03m-162.47 35.093c-6.623 0-11.78 5.188-11.78 11.812s5.157 11.78 11.78 11.78c6.625 0 11.814-5.156 11.814-11.78c0-6.627-5.188-11.813-11.813-11.813zm164.22 0c-6.624 0-11.78 5.188-11.78 11.812c-.002 6.624 5.156 11.78 11.78 11.78s11.812-5.156 11.812-11.78c0-6.627-5.187-11.813-11.812-11.813zm-81.406 51.906c38.762 0 68.875 36.01 68.875 78.593c0 19.938-2.457 56.192-11.532 88.687c-4.536 16.247-10.655 31.58-19.686 43.563c-9.03 11.98-21.96 20.812-37.656 20.812c-15.696 0-28.626-8.83-37.657-20.813c-9.03-11.98-15.15-27.315-19.686-43.562c-9.075-32.495-11.563-68.75-11.563-88.688c0-42.584 30.145-78.593 68.907-78.593zm0 18.686c-17.93 0-34.16 11.453-43.063 29.063h86.094c-8.895-17.61-25.103-29.064-43.033-29.064zm-27.282 173.938c1.45 2.532 2.956 4.878 4.53 6.97c6.78 8.99 13.692 13.373 22.75 13.373c9.06 0 15.943-4.383 22.72-13.375c1.576-2.09 3.08-4.436 4.53-6.968z"/>
        </svg>
      </div>
      <h3>{{ $t('whoops') }}</h3>
      <div>
          <p>{{ $t('ERRORS.technical') }}</p>
          <p>{{ $t('Please refresh page') }}</p>
      </div>
      <button-component
          :disabled="Boolean(seconds || processing)"
          @btn-click="requestKey"
          variant="bordered"
          title="reload page"
      >
        {{ seconds? $t('Reload page after', {seconds: seconds / 1000}) : $t('Reload page')}}
      </button-component>
    </div>
    <slot v-else/>
  </div>
</template>

<style scoped lang="scss">
.reload-wrapper {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  font-style: normal;

  p {
    margin: 0;
    padding: 0;
  }
}

.reload-wrapper__icon {
  max-width: 60vw;
  max-height: 40vh;

  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
