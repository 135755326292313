import request from './request';
import {COLOR_API, MENU_API, PAGES_API, SETTINGS_API, SLIDER_API} from '@/urls';
import {API_METHODS} from '@/const';

export default {
  get_channel_settings() {
    return request({
      url: SETTINGS_API,
      method: API_METHODS.GET,
      config: {
        retryCount: 1,
        globalError: true,
        localError: false,
        retryDelay: 1000,
        crashCodes: ['5\\d\\d', '4\\d\\d'],
        allowedCodes: [],
      },
    });
  },
  get_channel_menu() {
    return request({
      url: MENU_API,
      method: API_METHODS.GET,
      config: {
        retryCount: 1,
        globalError: true,
        localError: false,
        retryDelay: 1000,
        crashCodes: ['5\\d\\d', '4\\d\\d'],
        allowedCodes: [],
      },
    });
  },
  get_channel_slides({type} = {}) {
    // '', 'main', 'event_category', 'participant', 'participant_group'
    return request({
      url: SLIDER_API,
      method: API_METHODS.GET,
      params: type ? {type} : {},
      config: {
        retryCount: 1,
        globalError: false,
        localError: false,
        retryDelay: 1000,
        crashCodes: [],
        allowedCodes: [],
      },
    });
  },
  get_channel_pages() {
    return request({
      url: PAGES_API,
      method: API_METHODS.GET,
    });
  },
  get_channel_page(id) {
    return request({
      url: `${PAGES_API}/${id}`,
      method: API_METHODS.GET,
    });
  },
  get_channel_colors() {
    return request({
      url: COLOR_API,
      method: API_METHODS.GET,
    });
  },
};
