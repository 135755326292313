import request from '@/api/models/request';
import {API_METHODS} from '@/const';

/**
 * Class representing a SiteTracker.
 * This class is responsible for sending request error logs to Site24x7.
 */
class SiteTracker {
  #baseUrl = 'https://logc.site24x7.com';
  #url = '/event/receiver';
  #CAN_SEND = Boolean(process.env.VUE_APP_SITE24X7_API_KEY);
  #CHANNEL_NAME = process.env.VUE_APP_CHANNEL_CONFIG_NAME;
  #REQUEST_ERROR_LOG_TOKEN = process.env.VUE_APP_SITE24X7_REQUEST_ERROR_TOKEN;

  // 'REQUEST ERRORS' log for site24x7;
  /**
   * Sends a request error log to Site24x7.
   * If the conditions are met, it sends a POST request with error details
   * to the Site24x7 logging endpoint.
   * @param {Object} err - The error object containing details of the request error.
   * @param {Object} err.config - The configuration of the request that caused the error.
   * @param {string} err.config.baseURL - The base URL of the request.
   * @param {Object} err.response - The response object of the failed request.
   * @param {number} err.response.status - The HTTP status code of the response.
   * @param {string} [err.response.data.message] - The error message from the response data.
   * @param {string} [err.response.statusText] - The status text of the response.
   * @param {Object} err.request - The request object that caused the error.
   * @param {string} [err.request.responseURL] - The URL of the request that caused the error.
   */
  sendRequestError(err) {
    if (
      !this.#CAN_SEND ||
      !this.#REQUEST_ERROR_LOG_TOKEN ||
      err.config?.baseURL === this.#baseUrl
    ) return;
    request({
      url: `${this.#url}?token=${this.#REQUEST_ERROR_LOG_TOKEN}`,
      baseUrl: this.#baseUrl,
      method: API_METHODS.POST,
      needPrepareParams: false,
      config: {
        headers: {
          'Content-Type': 'application/json',
        },
      },
      params: {
        date: (new Date()).toISOString(),
        status: err.response.status,
        service: 'request',
        channel: this.#CHANNEL_NAME,
        message: err.response?.data?.message || err.response?.statusText,
        request_data: JSON.stringify(Object.fromEntries(err.config?.data?.entries?.() || [])),
        url: err.request?.responseURL,
      },
    }).catch(console.log);
  }
}

const siteTracker = new SiteTracker();
export default siteTracker;
