import api from '../../api/api';
import {GET_GEO_DATA} from '../actionTypes';
import {SET_IS_GEO_BLOCKED, UPDATE_GEO_DATA} from '../mutationTypes';

export default {
  [GET_GEO_DATA]: async ({commit}) => {
    const geoData = await api.geo.get_geo_data();
    commit(SET_IS_GEO_BLOCKED, geoData.is_geo_blocked);
    commit(UPDATE_GEO_DATA, geoData);
  },
};
