<script>

export default {
  name: 'LoaderScreen',
  balls: [
    {
      id: 1,
      svg: `<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M4.175 18.225q-1.05-1.35-1.612-2.937T2 12q0-1.7.563-3.287t1.612-2.938Q5.625 6.9 6.45 8.538T7.275 12q0 1.825-.825 3.463t-2.275 2.762M12 22q-1.8 0-3.437-.6t-2.988-1.75q1.725-1.425 2.7-3.412T9.25 12q0-2.25-.975-4.237t-2.7-3.413Q6.925 3.2 8.563 2.6T12 2q1.8 0 3.438.6t2.987 1.75q-1.725 1.425-2.7 3.413T14.75 12q0 2.25.975 4.238t2.7 3.412q-1.35 1.15-2.988 1.75T12 22m7.825-3.775q-1.45-1.125-2.275-2.762T16.725 12q0-1.825.825-3.463t2.275-2.762q1.05 1.35 1.613 2.938T22 12q0 1.7-.562 3.288t-1.613 2.937" />
    </svg>`,
    },
    {
      id: 2,
      svg: `<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 512 512">
    <path fill="currentColor" d="M511.8 267.4c-26.1 8.7-53.4 13.8-81 15.1C440 177.2 399.3 78.3 327.6 10.1C434.1 41.1 512 139.5 512 256c0 3.8-.1 7.6-.2 11.4m-3.9 34.7c-5.8 32-17.6 62-34.2 88.7c-97.5 48.5-217.7 42.6-311.9-24.5c23.7-36.2 55.4-67.7 94.5-91.8c79.9 43.2 170.1 50.8 251.6 27.6m-236-55.5C269.4 155.7 230.8 73.9 170 14.9C196.8 5.2 225.8 0 256 0c2.7 0 5.3 0 7.9.1c90.8 60.2 145.7 167.2 134.7 282.3c-43.1-2.4-86.4-14.1-126.8-35.9zM138 28.8c20.6 18.3 38.7 39.4 53.7 62.6C95.9 136.1 30.6 220.8 7.3 316.9C2.5 297.4 0 277 0 256C0 157.2 56 71.5 138 28.8m69.6 90.5c19.5 38.6 31 81.9 32.3 127.7c-77.4 47.6-129 121.9-149.7 204c-24.2-20.6-44.6-45.6-59.8-73.8c6.7-108.7 71.9-209.9 177.1-257.9zM256 512c-50.7 0-98-14.7-137.8-40.2c5.6-27 14.8-53.1 27.4-77.7c86.6 60.5 192.5 74.7 287.4 46.9c-46 44-108.3 71-177 71" />
    </svg>`,
    },
    {
      id: 3,
      svg: `<svg xmlns="http://www.w3.org/2000/svg" width="23.25px" height="24px" viewBox="0 0 496 512">
    <path fill="currentColor" d="M481.5 60.3c-4.8-18.2-19.1-32.5-37.3-37.4C420.3 16.5 383 8.9 339.4 8L496 164.8c-.8-43.5-8.2-80.6-14.5-104.5m-467 391.4c4.8 18.2 19.1 32.5 37.3 37.4c23.9 6.4 61.2 14 104.8 14.9L0 347.2c.8 43.5 8.2 80.6 14.5 104.5M4.2 283.4L220.4 500c132.5-19.4 248.8-118.7 271.5-271.4L275.6 12C143.1 31.4 26.8 130.7 4.2 283.4m317.3-123.6c3.1-3.1 8.2-3.1 11.3 0l11.3 11.3c3.1 3.1 3.1 8.2 0 11.3l-28.3 28.3l28.3 28.3c3.1 3.1 3.1 8.2 0 11.3l-11.3 11.3c-3.1 3.1-8.2 3.1-11.3 0l-28.3-28.3l-22.6 22.7l28.3 28.3c3.1 3.1 3.1 8.2 0 11.3l-11.3 11.3c-3.1 3.1-8.2 3.1-11.3 0L248 278.6l-22.6 22.6l28.3 28.3c3.1 3.1 3.1 8.2 0 11.3l-11.3 11.3c-3.1 3.1-8.2 3.1-11.3 0l-28.3-28.3l-28.3 28.3c-3.1 3.1-8.2 3.1-11.3 0l-11.3-11.3c-3.1-3.1-3.1-8.2 0-11.3l28.3-28.3l-28.3-28.2c-3.1-3.1-3.1-8.2 0-11.3l11.3-11.3c3.1-3.1 8.2-3.1 11.3 0l28.3 28.3l22.6-22.6l-28.3-28.3c-3.1-3.1-3.1-8.2 0-11.3l11.3-11.3c3.1-3.1 8.2-3.1 11.3 0l28.3 28.3l22.6-22.6l-28.3-28.3c-3.1-3.1-3.1-8.2 0-11.3l11.3-11.3c3.1-3.1 8.2-3.1 11.3 0l28.3 28.3z" />
    </svg>`,
    },
    {
      id: 4,
      svg: `<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 512 512">
    <path fill="currentColor" d="M248.37 41.094c-49.643 1.754-98.788 20.64-137.89 56.656L210.53 197.8c31.283-35.635 45.59-88.686 37.84-156.706m18.126.107c7.646 71.205-7.793 129.56-43.223 169.345L256 243.27L401.52 97.75c-38.35-35.324-86.358-54.18-135.024-56.55zM97.75 110.48c-36.017 39.102-54.902 88.247-56.656 137.89c68.02 7.75 121.07-6.557 156.707-37.84zm316.5 0L268.73 256l32.71 32.71c33.815-30.112 81.05-45.78 138.183-45.11c10.088.118 20.49.753 31.176 1.9c-2.37-48.665-21.227-96.672-56.55-135.02zM210.545 223.272c-39.785 35.43-98.14 50.87-169.344 43.223c2.37 48.666 21.226 96.675 56.55 135.025L243.27 256l-32.725-32.727zm225.002 38.27c-51.25.042-92.143 14.29-121.348 39.928l100.05 100.05c36.017-39.102 54.902-88.247 56.656-137.89c-12.275-1.4-24.074-2.096-35.36-2.087zM256 268.73L110.48 414.25c38.35 35.324 86.358 54.18 135.024 56.55c-7.646-71.205 7.793-129.56 43.223-169.345zm45.47 45.47c-31.283 35.635-45.59 88.686-37.84 156.706c49.643-1.754 98.788-20.64 137.89-56.656z" />
    </svg>`,
    },
    {
      id: 5,
      svg: `<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M19 16V6h3v10zM12 4H7S2 4 2 8v6c0 1.77 1 2.76 2.07 3.31A3.996 3.996 0 0 1 8 14h3v2H8a2 2 0 0 0-2 2a2 2 0 0 0 2 2h5c4 0 4-4 4-4V6s-1-2-5-2" />
    </svg>`,
    },
  ],
};
</script>

<template>
  <div class="loader">
    <div
      class="balls-loader"
      :style="{
          '--length': $options.balls.length
        }"
    >
      <div v-for="(ball, index) of $options.balls"
           :key="ball.id"
           class="item"
           v-html="ball.svg"
           :style="{
               '--index': index,
             }"
      />
    </div>
  </div>
</template>
<style lang="scss">
.balls-loader {
  --itemDelay: 420ms;
  --itemWidth: 1.2rem;
  color: var(--settings-body-text-color);
  position: relative;
  width: calc(calc(var(--length) * .65) * var(--itemWidth));
  height: 1.5rem;

  .item {
    animation: run calc(var(--itemDelay) * var(--length)) infinite linear;
    position: absolute;
    top: 0;
    left: 100%;
    width: var(--itemWidth);
    transform: scale(0);
    animation-delay: calc(var(--index) * var(--itemDelay));
    will-change: left, transform;
    @keyframes run {
      0% {
        left: 100%;
        transform: scale(0) translateY(-100%);
        opacity: 0;
      }
      10% {
        left: 100%;
        transform: scale(1) translateY(0);
        opacity: 1;
      }
      60% {
        left: 0;
        transform: scale(1) translateY(0);
        opacity: 1;
      }
      70% {
        left: 0;
        transform: scale(0) translateY(-100%);
        opacity: 0;
      }
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.loader {
  display: flex;
  justify-content: center;
}
</style>
