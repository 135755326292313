import {
  GET_BASIC_PASS_NEED,
  GET_BASIC_PASS_SELECTED,
  GET_CUSTOM_FORM_DATA_FOR_ALIAS,
} from '../getterTypes';
import {BASIC_PASS_FIELD_NAME, CUSTOM_FORM_ALIASES} from '@/const';

export default {
  [GET_CUSTOM_FORM_DATA_FOR_ALIAS]: (state) => (alias) => {
    return alias && state.customForm[alias] || state.customForm;
  },
  [GET_BASIC_PASS_SELECTED]: (state, getters) => {
    const form = state.customForm[CUSTOM_FORM_ALIASES.PASSES]?.find(
        ({name}) => name === BASIC_PASS_FIELD_NAME,
    );
    if (!getters[GET_BASIC_PASS_NEED]) return true;
    return form ? Boolean(Number(form?.value)) : true;
  },
  [GET_BASIC_PASS_NEED]: (state) => {
    return Boolean(state.customForm[CUSTOM_FORM_ALIASES.PASSES]);
  },
};
