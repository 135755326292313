import * as MUTATION from '../mutationTypes';

export default {
  [MUTATION.UPDATE_PACKAGES_LIST](state, data) {
    state.packagesList = data.data;
  },
  [MUTATION.UPDATE_SUBSCRIPTIONS_LIST](state, data) {
    state.subscriptionsList = data.data;
  },
  [MUTATION.UPDATE_ORDERS_LIST](state, data) {
    state.ordersList = Object.values(
        Object.fromEntries(
            [...data, ...state.ordersList].map((order) => [order.id, order]),
        ),
    );
  },
};
